import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/max';
import isUrlValidator from 'validator/lib/isURL';
import isEmailValidator from 'validator/lib/isEmail';

export function isPhoneNumber(value: string, region?: CountryCode): boolean {
  if (typeof value !== 'string' || value.trim() !== value) {
    return false;
  }

  try {
    const phoneNumber = parsePhoneNumber(value, region);

    /**
     * We fail the validation if the user provided a region code
     * and it doesn't match with the country code of the parsed number.
     **/
    if (region && phoneNumber.country !== region) {
      return false;
    }

    return phoneNumber.isValid();
  } catch (error) {
    return false;
  }
}

export function isNumber(value: string, decimalSeparator = '.'): boolean {
  if (typeof value !== 'string' || value.trim() !== value) {
    return false;
  }

  return !!value.match(`^[0-9${decimalSeparator == '.' ? '\\.' : decimalSeparator}]+?$`)?.length;
}

//Accepts years from 1000 to 3999
export function isYear(value: string): boolean {
  if (typeof value !== 'string' || value.trim() !== value) {
    return false;
  }

  return !!value.match(`^[1-3][0-9][0-9][0-9]$`)?.length;
}

export const validateUrl = (value: string) => {
  return isUrlValidator(value);
};

export const validateEmail = (value: string) => {
  return isEmailValidator(value);
};

export const parseError = (error: any) => {
  if (error.response) {
    if (error.response.data && error.response.status !== 401 && error.response.status !== 404) {
      return error.response.data;
    } else if (error.response.status === 404) {
      return {
        status: error.response.status,
        message: error.response.statusText
      };
    } else {
      return error.response;
    }
  }
  return error;
};
